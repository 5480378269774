import request from '@/utils/request';

// 登录
export const loginApi = (data) => request.post("/user/login.php", data);
// 用户信息
export const dhtUserInfoApi = (data) => request.post("/user/info.php", data);
// 用户列表
export const dhtUserListApi = (data) => request.post("/user/list.php", data);
// 更新用户密码
export const dhtUserUpdatePwdApi = (data) => request.post("/user/update_pwd.php", data);
// 添加用户
export const dhtUserAddApi = (data) => request.post("/user/add.php", data);
// 修改用户
export const dhtUserEditApi = (data) => request.post("/user/edit.php", data);
// 删除用户
export const dhtUserDelApi = (data) => request.post("/user/del.php", data);
// 获取用户权限
export const dhtUserPermissionApi = (data) => request.post("/user/permission.php", data);
// 设置超级管理员
export const dhtSetSAApi = (data) => request.post("/user/setsa.php", data);
// 部门api
// 部门列表
export const departmentList_api = (data) => request.post("/department/list.php", data)
// 添加部门
export const departmentAdd_api = (data) => request.post("/department/add.php", data)
// 修改部门
export const departmentEdit_api = (data) => request.post("/department/edit.php", data)
// 部门详情
export const departmentInfo_api = (data) => request.post("/department/info.php", data)
// 部门删除
export const departmentDel_api = (data) => request.post("/department/del.php", data)
// 部门选项列表
export const departmentOption_api = (data) => request.post("/department/option.php", data)
