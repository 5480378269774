import store from '@/store'

function checkPermission(el, binding, vnode, oldVnode) {
  const { value } = binding
  const actions = store.state.permission.actions || [];
  const userData = store.getters.userData;
  if (!Number(userData.is_sa)) {
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const permissionActions = value

        const hasPermission = actions.some(action => {
          // return permissionRoles.includes(role)
          return permissionActions.indexOf(action) !== -1
        })

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(`need actions! Like v-permission="['user_add','user_edit']"`)
    }
  }
}

export default {
  bind: function (el, binding, vnode, oldVnode) { },
  inserted: function (el, binding, vnode, oldVnode) {
    // console.log('inserted');
    checkPermission(el, binding, vnode, oldVnode);
  },
  update: function (el, binding, vnode, oldVnode) { },
  componentUpdated: function (el, binding, vnode, oldVnode) {
    // console.log('componentUpdated');
    checkPermission(el, binding, vnode, oldVnode);
  },
  unbind: function (el, binding, vnode, oldVnode) { }
};