export default {
  loopBgColor(index, colorList) {
    colorList = colorList || ['#67C23A', '#00CC9A', '#1FB7FF', '#F19623', '#B5B5B5', '#9459F0', '#FF716D', '#3E7DAF'];
    let colorLen = colorList.length;
    let colorIndex = index >= colorLen ? index % colorLen : index;
    return colorList[colorIndex];
  },
  checkEmail(str) {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str);
  },
  checkMobile(str) {
    return /^1[3456789][0-9]{9}$/.test(str);
  },
  checkPassword(str) {
    return /^[0-9a-zA-Z_\!\@\#\$\%\^\&\*\-]{6,32}$/.test(str);
  },
  isEmptyObject(obj) {
    for (let k in obj) {
      return false;
    }
    return true;
  },
  inArray(val, arr) {
    return arr.indexOf(val) > -1;
  },
  typeof(v) {
    return Object.prototype.toString.call(v).split(/\s+/)[1].slice(0, -1).toLowerCase();
  },
  random(length) {
    length = length || 6;
    var random_str = '';
    for (var i = 0; i < length; i++) {
      random_str += Math.floor(Math.random() * 10);
    }
    return random_str;
  },
  getDaysInYearMonth(year, month) {
    month = parseInt(month, 10) + 1;
    var date = new Date(year, month, 0);
    return date.getDate();
  },
  getEndDateByYearMonth(year, month) {
    let y = parseInt(year) || new Date().getFullYear();
    let m = parseInt(month) || (new Date().getMonth() + 1);
    let d = new Date(year, month, 0).getDate();
    return y + '-' + (m < 10 ? '0' + m : m) + '-' + d;
  },
  numberFormat(number, decimals) {
    var n = (Number(number) || 0).toString();
    var nLen = n.length;
    var res = '';
    while (nLen > 3) {
      res = ',' + n.slice(-3) + res;
      n = n.slice(0, nLen - 3);
    }
    if (n) {
      res = n + res;
    }
    return res;
  },
  arraySplit(arr, len) {
    if (!arr) {
      return [];
    }
    len = len || 1;
    var arr_len = arr.length;
    var res = [];
    for (var i = 0; i < arr_len; i += len) {
      res.push(arr.slice(i, i + len));
    }
    return res;
  },
  uuid(hyphen) {
    hyphen = hyphen === undefined ? '-' : hyphen;
    function S4(i) {
      i = i || 1;
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(i);
    }
    return (S4() + S4() + hyphen + S4() + hyphen + '4' + S4(2) + hyphen + S4() + hyphen + S4() + S4() + S4());
  }
};