export default {
  namespaced: true,
  state: {
    activeIndex: {},
    list: []
  },
  actions: {},
  mutations: {
    navMenuChange(state, payload) {
      let level = payload.level || 0;
      let pathArr = payload.path.split('/');
      pathArr[0] === '' && pathArr.shift();
      state.activeIndex = {};
      let index = 0;
      for (let i = 2, il = level + 1; i < il; i++) {
        state.activeIndex[i] = (i == 2 ? '/' : '') + pathArr[index];
        index += 1;
      }
    },
    navMenuList(state, payload) {
      state.list = payload.navMenuList;
    }
  },
  getters: {
    navMenuSubList(state) {
      for (let i = 0, il = state.list.length; i < il; i++) {
        let d = state.list[i];
        if (d.index === state.activeIndex[2]) {
          return d.children;
        }
      }
      return [];
    }
  }
};