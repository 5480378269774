export default {
  namespaced: true,
  state: {
    actions: []
  },
  mutations: {
    setActions(state, payload) {
      state.actions = payload;
    }
  }
};