import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/theme/index.css';
// 引入全局公共函数
import udf from '@/utils/function.js';
import permission from './directives/permission';

//引入富文本样式
//注意：这里使用的$message,所以在使用时候也是this.$message
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 节流
Vue.directive('throttle', {
  inserted: function (el, binding) {
    let timer = null
    let [fn, event = 'input', delay = 500] = binding.value
    el.addEventListener(event, () => {
      timer && clearTimeout(timer)
      timer = setTimeout(fn, delay)
    })
  }
})

Vue.prototype.$udf = udf;
// 关闭Vue的生产环境提示
Vue.config.productionTip = false;
// 使用ElementUI插件
Vue.use(ElementUI);

Vue.directive('permission', permission)

localStorage.removeItem('dht_user_data');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
