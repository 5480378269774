<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/* @media screen and (max-width: 1500px) {
  .box {
    display: table;
  }
} */

@font-face {
  font-family: PuHuiTi_Light;
  src: url("@/assets/font/PuHuiTi_Light.ttf");
}

@font-face {
  font-family: PuHuiTi_Regular;
  src: url("@/assets/font/PuHuiTi_Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-puhuiti-regular {
  font-family: PuHuiTi_Regular !important;
}

.font-puhuiti-light {
  font-family: PuHuiTi_Light !important;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

#app {
  height: 100%;
  width: 100%;
}

.el-container {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  #app {
    height: max-content;
    width: max-content;
  }

  .el-container {
    height: max-content;
    width: max-content;
  }
}



.el-drawer .el-drawer__header {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 3px;
}

.el-drawer .el-drawer__header .custom-title {
  font-weight: bold;
  font-size: 21px;
  color: #1a1a1a;
  margin-bottom: 5px;
}

.el-drawer .el-drawer__header .custom-sub-title {
  font-weight: bold;
  font-size: 17px;
  color: #1a1a1a;
  margin-bottom: 11px;
}

/* input number 去掉小箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* tab切换样式 */
.el-tabs__nav {
  background-color: rgba(250, 250, 250, 1);
  border-bottom: 1px solid #E4E7ED !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item {
  border-bottom: 1px solid #E4E7ED;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.el-tabs__item.is-active {
  color: rgba(217, 76, 76, 1);
  background-color: #fff;

}

.el-tabs__item:hover {
  color: rgba(217, 76, 76, 1);
  cursor: pointer;
}
</style>
